import { Box } from '@mui/material';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import {
  selectCommon,
  setFusionOneUserData,
  setUserData
} from '../common/services/common.service';
import { useDispatch, useSelector } from 'react-redux';
import { setUserDataToLocalStore } from 'src/auth/RBAC/utils';
import { goToRoute } from 'src/auth/login/Utils';
import { useAuth } from 'src/providers/AuthguardContext';
import { BASEPATH, DATA_COPILOT } from 'src/shared/constants/routes';

const ReceiveTokenModule = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();
  const { userData } = useSelector(selectCommon);
  const { login } = useAuth();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const fusion1Url = queryParams.get('fusion1');

  const ifRedirectionSuccess = localStorage.getItem('redirectionSuccess');
  // OR for exact match

  const handleMessage = async (event) => {
    if (
      event.origin !== process.env.REACT_APP_FUSION_ONE_DOMAIN_URL ||
      !event?.data?.data?.accessToken ||
      ifRedirectionSuccess
    ) {
      return;
    }

    try {
      const { accessToken, userId, onboardingStatus } = event?.data?.data;
      const payload = {
        token: accessToken,
        onboardingStatus: onboardingStatus,
        userUid: userId
      };

      const newUserData = await setUserDataToLocalStore(payload, !!fusion1Url);
      await dispatch(setUserData(newUserData));
    } catch (error) {
      console.error('error', error);
    }
    //}
  };

  useEffect(() => {
    // Listen for postMessage events
    window.addEventListener('message', handleMessage);

    // Clean up the event listener on component unmount
    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  // In ReceiveTokenModule.tsx
  useEffect(() => {
    if (
      !Object.keys(userData).length ||
      !userData?.accessToken ||
      ifRedirectionSuccess
    ) {
      return;
    }
    localStorage.setItem('redirectionSuccess', 'true');
    const navigateToPath = async () => {
      try {
        await login(userData);
        if (fusion1Url) {
          dispatch(setFusionOneUserData('copilot'));
          navigate(`/${BASEPATH}/${DATA_COPILOT}`);
        } else {
          const route = goToRoute(userData);
          navigate(`/${route}`, { replace: true });
        }
      } catch (error) {
        console.error('Navigation error:', error);
      }
    };

    navigateToPath();
  }, [userData]);

  return (
    <Box className="spinnerWrapper flex-basic-center mt-100 mb-100 ">
      <Box className="spinnerLoading "></Box>
    </Box>
  );
};

export default ReceiveTokenModule;
